<template>
	<div>
		<scNav navTag="scGpDail" :themeColor="themeColor"></scNav>
		<!-- 面包屑 -->
		<div style="max-width: 1440px;margin: 0 auto;">
			<crumbs :crumbs="['首页','专享产品服务','专享产品服务详情']" @clkItem="clkItem"></crumbs>
		</div>
		<div class="sc-width">
			<probm :probm="probm"></probm>
		</div>
		<bottom :bgColor="themeColor"></bottom>
	</div>
</template>

<script>
	import scNav from '@/components/special/scNav'
	import probm from "@/components/probm/probm"
	import moment from "moment"
	import util from "@/assets/script/util.js"
	export default {
		data() {
			return {
				themeColor:""
			}
		},
		components:{
			probm,
			scNav
		},
		created() {
			let type = this.$route.query.type
			let id = this.$route.query.id
			this.getNewDeal(id,type)
			if(type==1) {
				// 科技创新版
				this.themeColor = "#386eb8"
				
			}else if(type==2) {
				// 专精特新版
				this.themeColor = "#47a8c8"
			}else if(type==3) {
				// 交易版
				this.themeColor = "#d3a21e"
			}
		},
		data() {
			return {
				probm:{}
			}
		},
		methods:{
			// 查询新闻详情页
			async getNewDeal(id,type) {
				let url = ""
				if(type==1) {
					url = "Specialization/getDetail"
				}else if(type==2) {
					url = "Technology/getDetail"
				}else if(type==3) {
					url = "Trading/getDetail"
				}
				let res = await this.$axios.get(url,{params:{id}})
				if(res.code) {
					res.data.inputtime = moment(parseInt(res.data.inputtime*1000)).format("YYYY-MM-DD")
					if(res.data.subject) {
						res.data.subject = util.setContent(res.data.subject)
					}
					if(res.data.content) {
						res.data.content = util.setContent(res.data.content)
					}
					
					this.probm = res.data
				}
			},
			clkItem(index) {
				let type = this.$route.query.type
				if(index==0) {
					this.$router.push({name:"scIndex",query:{type}})
				}else if(index==1) {
					this.$router.go(-1)
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	/deep/.time p:last-child {
		margin-left: 0;
	}
</style>